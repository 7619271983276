import * as React from "react";
import { init } from "@socialgouv/matomo-next";
import { NextIntlProvider } from "next-intl";
import { SessionProvider, useSession } from "next-auth/react";
import { RelayEnvironmentProvider } from "react-relay/hooks";
import * as RelayEnv from "src/RelayEnv.bs";

// react-native-web static init
if (typeof window === "undefined") {
  require("react-native").Dimensions.set({
    window: {
      width: 360,
      height: 640,
    },
    screen: {
      width: 360,
      height: 640,
    },
  });
}

const RelayEnvironmentProvider_SessionAware = ({ Component, pageProps }) => {
  // change token asap
  const session = useSession();
  RelayEnv.jwt.contents = session.data?.user?.jwt;
  RelayEnv.id.contents = session.data?.user?.id;
  React.useEffect(() => {
    console.log("session *********", session);
  }, [session]);

  const relayEnvironment = RelayEnv.useRelayEnvironment(pageProps);

  return (
    <RelayEnvironmentProvider environment={relayEnvironment}>
      <Component {...pageProps} />
    </RelayEnvironmentProvider>
  );
};

const App = ({ Component, pageProps: { session, ...pageProps } }) => {
  React.useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      init({
        url: process.env.NEXT_PUBLIC_ANALYTICS_url,
        jsTrackerFile: process.env.NEXT_PUBLIC_ANALYTICS_jsTrackerFile,
        phpTrackerFile: process.env.NEXT_PUBLIC_ANALYTICS_phpTrackerFile,
        siteId: process.env.NEXT_PUBLIC_ANALYTICS_siteId,
      });
    }
  }, []);

  return (
    <NextIntlProvider
      messages={pageProps.messages}
      // // To achieve consistent date, time and number formatting
      // // across the app, you can define a set of global formats.
      // formats={{
      //   dateTime: {
      //     short: {
      //       day: 'numeric',
      //       month: 'short',
      //       year: 'numeric',
      //     },
      //   },
      // }}
      // // Providing an explicit value for `now` ensures consistent formatting of
      // // relative values regardless of the server or client environment.
      // now={new Date(pageProps.now)}
      // // Also an explicit time zone is helpful to ensure dates render the
      // // same way on the client as on the server, which might be located
      // // in a different time zone.
      // timeZone="Austria/Vienna"
    >
      <SessionProvider session={session}>
        <RelayEnvironmentProvider_SessionAware
          Component={Component}
          pageProps={pageProps}
        />
      </SessionProvider>
    </NextIntlProvider>
  );
};

export default App;
