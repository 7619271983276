// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as Webapi__Fetch from "rescript-webapi/src/Webapi/Webapi__Fetch.bs.js";
import * as RelayRuntime from "relay-runtime";
import * as Browser from "@sentry/browser";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";

var Graphql_error = /* @__PURE__ */Caml_exceptions.create("RelayEnv.Graphql_error");

var endpoint = process.env.NEXT_PUBLIC_ENDPOINT + "/graphql";

var relayKey_INITIAL_RECORDS_PROP = "__RELAY_INITIAL_RECORDS__";

var jwt = {
  contents: undefined
};

var id = {
  contents: undefined
};

function makeFetchQuery(operation, variables, _cacheConfig, _uploadables) {
  var authorization = Belt_Option.getWithDefault(Belt_Option.map(jwt.contents, (function (jwt) {
              return "Bearer " + jwt;
            })), "");
  console.log("RelayEnv / fetchQuery", {
        id: id.contents,
        jwt: jwt.contents,
        operation: operation,
        variables: variables,
        authorization: authorization
      });
  return fetch(endpoint, Webapi__Fetch.RequestInit.make(/* Post */2, {
                      "content-type": "application/json",
                      accept: "application/json",
                      Authorization: authorization
                    }, Caml_option.some(JSON.stringify(Object.fromEntries([
                                  [
                                    "query",
                                    operation.text
                                  ],
                                  [
                                    "variables",
                                    variables
                                  ]
                                ]))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (resp) {
              if (resp.ok) {
                return resp.json();
              } else {
                console.error("RelayEnv / Fetch query error", jwt.contents, operation, variables);
                if (process.env.NODE_ENV !== "development") {
                  Browser.captureMessage("Grapqhl error", {
                        user: id.contents,
                        extra: {
                          operation: operation,
                          variables: variables
                        }
                      });
                }
                return Promise.reject({
                            RE_EXN_ID: Graphql_error,
                            _1: "Request failed: " + resp.statusText
                          });
              }
            });
}

function createEnvironment(param) {
  return RescriptRelay.Environment.make(RelayRuntime.Network.create(makeFetchQuery, undefined), RescriptRelay.Store.make(new RelayRuntime.RecordSource(undefined), 10, undefined, undefined), undefined, undefined, undefined, undefined, typeof window === "undefined", undefined);
}

function initializeRelay(initialRecords) {
  var environment = createEnvironment(undefined);
  if (initialRecords !== undefined) {
    environment.getStore().publish(new RelayRuntime.RecordSource(Caml_option.valFromOption(initialRecords)));
  }
  if (typeof window === "undefined") {
    environment.getStore().holdGC();
  }
  return environment;
}

function finalizeRelay(environment, pageProps) {
  pageProps.props = Caml_option.some(Belt_Option.getWithDefault(pageProps.props, {}));
  pageProps.props = Belt_Option.map(pageProps.props, (function (props) {
          props[relayKey_INITIAL_RECORDS_PROP] = environment.getStore().getSource().toJSON();
          return props;
        }));
  return pageProps;
}

function useRelayEnvironment(pageProps) {
  var initialRecords = pageProps[relayKey_INITIAL_RECORDS_PROP];
  return React.useMemo((function () {
                return initializeRelay(initialRecords);
              }), [initialRecords]);
}

export {
  Graphql_error ,
  endpoint ,
  relayKey_INITIAL_RECORDS_PROP ,
  jwt ,
  id ,
  makeFetchQuery ,
  createEnvironment ,
  initializeRelay ,
  finalizeRelay ,
  useRelayEnvironment ,
}
/* endpoint Not a pure module */
